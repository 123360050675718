import React, { useState } from 'react'
import { useEffect } from 'react'
import AddNewRouteScheduleForm from './AddNewRouteScheduleForm'

export default function SelectorContainer({ locations, setStore, store, drivers, weekday, courierPartners, fetchRouteContainerAPI}) {
  const onChange = (e) => {
    e.preventDefault()
    const storeSelection = JSON.parse(e.target.value)
    setStore(storeSelection)

    history.pushState("", "", `/route/dashboard/${storeSelection.id}/monday`)
  }

  const [closeModal, setCloseModal] = useState(false);

  function handleClick(e) {
    e.preventDefault();
    setCloseModal(false)
  }

  return (
    <div style={{
      width: '100%',
      display: 'flex',
      padding: '1%',
      justifyContent: 'space-between'
    }}>
      <div style={{ width: '82.5%' }}>
        <form >
          <label style={{ marginRight: '.5%' }}> Route Templates for  </label>
          <select onChange={onChange} value={JSON.stringify(store)} name='location' className="form-control" style={{ width: "12%" }}>
            <option value =""> Select a Store </option>
            {
              locations.map(location => {
                return (<option value={JSON.stringify(location)} key={location.id}> {location.name} </option>)
              })
            }
          </select>
        </form>
      </div>

      <div style={{ textAlign: "right", width: '10%' }}>
        <button data-toggle="modal" data-target="#add-new-route-modal" data-backdrop="static" data-keyboard="false" className="btn btn-default" onClick={handleClick} style={{ backgroundColor: "#dffc03" }}> + Add New Route </button>
        <AddNewRouteScheduleForm fetchRouteContainerAPI={fetchRouteContainerAPI} 
        drivers={drivers} locations={locations} closeModal={closeModal} setCloseModal={setCloseModal} store={store} weekday={weekday} courierPartners={courierPartners}
        />
      </div>
    </div>
  )

}
