import React, { useState, useEffect } from 'react'

import SelectorContainer from './SelectorContainer'

import WeekdayTabContainer from './WeekdayTabContainer'

export default function RouteSchedulingDashboard(props) {
    const locations = props.storeLocations
    const courierPartners = props.courierPartners
    const { selected_day_of_week, selected_location_id } = props
    const [store, setStore] = useState(() => {
        for (const location of locations) {
            if (selected_location_id == location.id) {
                return location
            }
        }
        return {};
    });
    const [routeTemplates, setRouteTemplates] = useState([])
    const [unscheduledRouteContainerLoading, setUnscheduledRouteContainerLoading]= useState(false)
    const [weekday, setWeekday] = useState(selected_day_of_week || "monday")

    useEffect(()=>{
        if(selected_day_of_week != null && selected_day_of_week != weekday){
            setWeekday(selected_day_of_week)
        }
        if (selected_location_id != null && selected_location_id != store.id) {
            for (const location of locations) {
                if (selected_location_id == location.id) {
                    setStore(location)
                }
            }
        }
    }, [selected_day_of_week, selected_location_id])

    useEffect(() => {
        history.replaceState("", "", `/route/dashboard/${store.id}/${weekday}`);
        document.title = `${store.name} ~ ${weekday.replace(/^./, weekday[0].toUpperCase())}`;
    }, [store, weekday])

    async function fetchRouteContainerAPI() {
        setUnscheduledRouteContainerLoading(true)
        let response = await fetch(`/api/route_templates?day_of_week=${weekday}&location_id=${store.id}`)
        response = await response.json()
        setRouteTemplates(response.route_templates.sort((a,b) => a.id - b.id))
        setUnscheduledRouteContainerLoading(false)
    }

    useEffect(() => {
        fetchRouteContainerAPI()
    }, [store, weekday])

    const [upcomingLaunches, setUpcomingLaunchesState] = useState([])
    const [upcomingLaunchesLoading, setUpcomingLaunchesLoading] = useState(false)

    useEffect(() => {
        async function fetchMyAPI() {
            setUpcomingLaunchesLoading(true)
            let response = await fetch(`/api/launches?parent_location_id=${store.id}&only_upcoming=true`)
            response = await response.json()
            setUpcomingLaunchesState(response.launches)
            setUpcomingLaunchesLoading(false)
        }
        fetchMyAPI()
    }, [store])

    return (
        <div>
            <SelectorContainer fetchRouteContainerAPI={fetchRouteContainerAPI} drivers={props.drivers} locations={locations} setStore={setStore} store={store}  weekday={weekday} courierPartners={courierPartners}></SelectorContainer>
            <WeekdayTabContainer  fetchRouteContainerAPI={fetchRouteContainerAPI} upcomingLaunches={upcomingLaunches} drivers={props.drivers} locations={locations} store={store} upcomingLaunchesLoading={upcomingLaunchesLoading} unscheduledRouteContainerLoading={unscheduledRouteContainerLoading} setUnscheduledRouteContainerLoading={setUnscheduledRouteContainerLoading} routeTemplates={routeTemplates} setRouteTemplates={setRouteTemplates} weekday={weekday} setWeekday={setWeekday} courierPartners={courierPartners}></WeekdayTabContainer>
        </div>
    )
}
